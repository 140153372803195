import { Component } from 'react';
import { connect } from 'react-redux';
import './SuperFab.styles.css';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import Backdrop from '@mui/material/Backdrop';

import AccountDrawerButton from '../AccountDrawer/AccountDrawerButton';

class SuperFab extends Component {
    constructor (props) {
        super(props);
        this.clickDeleteSet = this.clickDeleteSet.bind(this);
    }

    donateButton (e) {
        window.open('https://www.paypal.com/donate/?hosted_button_id=A7VN52SDW3M2A', 'popUpWindow')
        e.preventDefault();
    }
    clickDeleteSet (e) {
        try {
            this.props.deleteSet(this.props.sets[this.props.currentSet]);
            this.props.toggleSetFilter();
        } catch (e) {
            // better error handling.
            console.error(e.message);
        }
        
        e.preventDefault();
    }
    renderDeleteSet () {
        if (this.props.currentSet > 0 && !this.props.isEditModeDisabled) {
            return (
                <Fab className="modification-icon" size="small" aria-label="Delete Set" color="error" onClick={this.clickDeleteSet}>
                     <FolderDeleteIcon />
                </Fab>
            );
        }
    }

    renderShareButtons () {
        if (this.props.currentSet === 0) {
            return (
                <Fab className="modification-icon" size="small" aria-label="Edit" color={ (!this.props.isExportDialogOpen) ? 'default' : 'primary'} onClick={this.props.toggleExportDialog}>
                    <ShareIcon />
                </Fab>
            );
        }
    }

    render () {
        return (
            <div>
                <Backdrop open={this.props.isSuperFabOpen}  />
                <SpeedDial
                    ariaLabel="Link Modification Menu"
                    sx={{ position: 'fixed', bottom: 30, right: 30, top: 'auto', left: 'auto', margin: 0 }}
                    icon={ (this.props.isEditModeDisabled) ? <SpeedDialIcon /> : <EditOffIcon /> }
                    FabProps={{
                        color: (this.props.isEditModeDisabled) ? 'secondary' : 'error' }
                    }
                    onClick={(this.props.isEditModeDisabled) ? this.props.openSuperFab : this.props.toggleEditMode}
                    onClose={this.props.closeSuperFab}
                    onOpen={this.props.openSuperFab}
                    open={this.props.isSuperFabOpen}
                >
                    <SpeedDialAction 
                        icon={<AddIcon />}  
                        tooltipTitle="Create New Link Collection"
                        onClick={ this.props.toggleNewGroupDialog }
                    />
                    <SpeedDialAction 
                        icon={(this.props.isEditModeDisabled) ? <EditIcon /> : <EditOffIcon />}
                        FabProps={{
                            color: (this.props.isEditModeDisabled) ? 'default' : 'error' }
                        }
                        tooltipTitle="Edit My Links & Collections"
                        onClick={this.props.toggleEditMode}
                    />
                    <SpeedDialAction 
                        icon={<ShareIcon />}
                        tooltipTitle="Share My Link Group"
                        onClick={this.props.toggleExportDialog}
                    />
                    <SpeedDialAction 
                        icon={<DriveFolderUploadIcon />}
                        tooltipTitle="Import Group"
                        onClick={this.props.toggleImportDialog}
                    />
                    <SpeedDialAction 
                        icon={<FolderDeleteIcon />}
                        tooltipTitle="Delete Shared Group"
                        onClick={this.props.toggleDeleteSetDialog}
                    />
                    <SpeedDialAction 
                        className='fab-donate'
                        icon={<SportsBarIcon />}
                        tooltipTitle="Buy me a &#127866;"
                        onClick={this.donateButton}
                    />
                </SpeedDial>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleEditMode: () => dispatch({type: 'ui/toggleEditMode'}),
        toggleNewGroupDialog: () => dispatch({type: 'ui/toggleNewGroupDialog'}),
        toggleExportDialog: () => dispatch({type: 'ui/toggleExportDialog'}),
        toggleImportDialog: () => dispatch({type: 'ui/toggleImportDialog'}),
        toggleDeleteSetDialog: () => dispatch({type: 'ui/toggleDeleteSetDialog'}),
        toggleSetFilter: () => dispatch({type: 'ui/toggleSetFilter'}),
        openSuperFab: () => dispatch({type: 'ui/openSuperFab'}),
        closeSuperFab: () => dispatch({type: 'ui/closeSuperFab'}),
        deleteSet: (payload) => {dispatch({type: "hyperlink/deleteSet", payload})}
    }
};

const mapStateToProps = (state) => {
    return {
        isEditModeDisabled: state.ui.isEditModeDisabled,
        isNewGroupDialogOpen: state.ui.isNewGroupDialogOpen,
        isExportDialogOpen: state.ui.isExportDialogOpen,
        isImportDialogOpen: state.ui.isImportDialogOpen,
        isSuperFabOpen: state.ui.isSuperFabOpen,
        currentSet: state.ui.currentSet,
        sets: state.hyperlink.sets,
        groups: state.hyperlink.groups
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperFab);