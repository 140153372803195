import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import CopyButton from '../CopyButton/CopyButton';
import NotificationError from '../NotificationError/NotificationError';
import { encryptGroups } from '../../utils/helpers/crypto.helpers';
import './CreateSnapshot.styles.css';

class CreateSnapshot extends Component {
    constructor (props) {
        super(props);
        this.state = {
            encryptedSet: '',
            errorMsg: ''
        };

        this.secretInput = createRef('');

        this.handleCreateSnapshot = this.handleCreateSnapshot.bind(this);
    }

    handleCreateSnapshot () {
        let invalidSecret = 'Secret must be 16 (AES128), 24 (AES192), or 32 (AES256) characters in length.'
        if (this.secretInput && this.secretInput.current && this.props.groups) {
            let key = this.secretInput.current.value;
            if (key.length === 16 || key.length === 24 || key.length === 32) {
                let encryptedSet = encryptGroups(key, this.props.groups.filter(group => group.set === 'default'));
                this.setState({
                    encryptedSet,
                    errorMsg: ''
                })
            } else {
                this.setState({
                    errorMsg: invalidSecret
                });
            }
        } else {
            this.setState({
                errorMsg: invalidSecret
            });
        }
    }

    renderEncryptedSetArea () {
        if (!this.props.snapshotMode) {
            return (
                <Grid item xs={12}>
                    <TextareaAutosize
                        aria-label="The encrypted shareable Group will generate here."
                        minRows={15}
                        placeholder="The encrypted shareable Group will generate here."
                        value={ this.state.encryptedSet }
                        style={{ width: '95%', marginTop: '20px' }}
                    />
                </Grid>
            );
        }
    }
    render () {
        let helperText = 
            (this.props.snapshotMode) ? 
            'You will need to remember this secret to restore snapshot.' : 
            'A secret is required to decrypt the encrypted Group. This secret will need to be provided to the recipient if you intend to share your Group.';

        return (
            <Grid 
                className={(this.props.snapshotMode) ? 'create-snapshot-grid' : 'general-export'}
                container
                rowSpacing={2} 
                direction="row"
                justifyContent="center"
                alignItems="center">
                <NotificationError message={this.state.errorMsg} />                
                <Grid item xs={12}>
                    <TextField
                        autoFocus={!this.props.snapshotMode}
                        required
                        margin="dense"
                        id="encryption-key"
                        label="Secret"
                        variant="outlined"
                        inputRef={this.secretInput}
                        helperText= {helperText}
                    />
                </Grid>
                { this.renderEncryptedSetArea() }
                <Grid direction="row" container
                rowSpacing={2} 
                justifyContent="right"
                alignItems="left">
                    <Grid item xs={12} style={{ display: (this.props.snapshotMode) ? 'none' : 'inline-flex'}}>
                        <CopyButton copyLabel="Copy" content={ this.state.encryptedSet } />
                        <CopyButton copyLabel="Copy Share Link" content={ this.state.encryptedSet } />
                    </Grid>  
                    <Grid item xs={12}>
                        <Button onClick={this.handleCreateSnapshot}>
                            { (this.props.snapshotMode) ? 'Create Snapshot' : 'Generate' }
                        </Button>
                    </Grid>
                           
                </Grid>
            </Grid>  
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAccountDrawer: () => {dispatch({type: "ui/toggleAccountDrawer"})},
    }
};

const mapStateToProps = (state) => {
    return {
        isAccountDrawerOpen: state.ui.isAccountDrawerOpen,
        groups: state.hyperlink.groups
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSnapshot);