
import './Groups.styles.css';
import { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import Collection from '../Collection/Collection';
import NewCollection from '../NewCollection/NewCollection';
import ExportSet from '../ExportSet/ExportSet';
import ImportSet from '../ImportSet/ImportSet';
import DeleteSet from '../DeleteSet/DeleteSet';
import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';

import FuzzySearch from 'fuzzy-search';

class Groups extends Component {
    
    filterGroups () {
        if (this.props.groups && this.props.searchTerm && this.props.searchTerm.length !== '') {
            const searcher = new FuzzySearch(this.props.groups, ['collection.name', 'name', 'collection.alias', 'collection.url', 'collection.tags'], {
                caseSensitive: false,
              });
              return searcher.search(this.props.searchTerm);
        } 
        else if (this.props.groups) {
            return this.props.groups;
        }
        else {
            return [];
        }
    }

    render () {
        return (
            <Grid id="group-container" className="groups" container direction="row" justifyContent="center" alignItems="top" spacing={2} >
                { this.filterGroups().map((group, index) => {
                    return <Collection key={index} groupName={group.name} collection={group.collection} groupSet={group.set} />
                })}
                <NewCollection />
                <ExportSet />
                <ImportSet />
                <DeleteSet />
                <ReleaseNotes />
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSetFilter: (payload) => {dispatch({type: "ui/toggleSetFilter", payload})},
    }
};

const mapStateToProps = state => {
    return {
      groups: state.hyperlink.groups,
      sets: state.hyperlink.sets,
      currentSet: state.ui.currentSet,
      searchTerm: state.ui.searchTerm
    }
 }

 export default connect(mapStateToProps, mapDispatchToProps)(Groups);
