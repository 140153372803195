
const starWarsMoonsAndPlanets = [
    'Abafar',
    'Ahch-To',
    'Ajan Kloss',
    'Akiva',
    'Alderaan',
    'Aaleen',
    'Alzoc III',
    'Anaxes',
    'Ando',
    'Anoat',
    'Atollon',
    'Balnab',
    'Batuu',
    'Bespin',
    'Bogano',
    'Bracca',
    'Cantonica',
    'Castilon',
    'Cato Neimoidia',
    'Chandrila',
    'Christophsis',
    'Concord Dawn',
    'Corellia',
    'Coruscant',
    'Crait',
    'D\'Qar',
    'Dagobah',
    'Dantooine',
    'Dathomir',
    'Devaron',
    'Eadu',
    'Endor',
    'Er\'kit',
    'Eriadu',
    'Tarkin',
    'Esseles',
    'Exegol',
    'Felucia',
    'Florrum',
    'Fondor',
    'Geonosis',
    'Hosnian Prime',
    'Hoth',
    'Iego',
    'Ilum',
    'Iridonia',
    'Jakku',
    'Jedha',
    'Jelucan',
    'Jestefad',
    'Kamino',
    'Kashyyyk',
    'Kef Bir',
    'Kessel',
    'Kijimi',
    'Kuat',
    'Lah\'mu',
    'Lothal',
    'Lotho Minor',
    'Malachor',
    'Malastare',
    'Mandalore',
    'Maridun',
    'Mimban',
    'Mon Cala',
    'Moraband',
    'Mortis',
    'Mustafar',
    'Mygeeto',
    'Naboo',
    'Nal Hutta',
    'Nevarro',
    'Numidian Prime',
    'Onderon',
    'Ord Mantell',
    'Pasaana',
    'Pillio',
    'Polis Massa',
    'Rishi',
    'Rodia',
    'Rugosa',
    'Ruusan',
    'Ryloth',
    'Saleucami',
    'Savareen',
    'Scarif',
    'Serenno',
    'Shili',
    'Sissubo',
    'Skako Minor',
    'Sorgan',
    'Starkiller Base',
    'Subterrel',
    'Sullust',
    'Takodana',
    'Tatooine',
    'Teth',
    'Toydaria',
    'Trandosha',
    'Umbara',
    'Utapau',
    'Vandor-1',
    'Vardos',
    'Wobani',
    'Wrea',
    'Yavin',
    'Yavin 4',
    'Zeffo',
    'Zygerria',
    'Abregado-rae',
    'Ambria',
    'Anoth',
    'Arkania',
    'Bakura',
    'Bonadan',
    'Borleias',
    'Byss',
    'Carida',
    'Da Soocha V',
    'Drall',
    'Dromund Kaas',
    'Dxun',
    'Hapes',
    'Honoghr',
    'Ithor',
    'J\'t\'p\'tan',
    'Khomm',
    'Korriban',
    'Kothlis',
    'Lwhekk',
    'Muunilinst',
    'Myrkr',
    'N\'zoth',
    'Nkllon',
    'Ralltiir',
    'Rattatak',
    'Sacorria',
    'Selonia',
    'Thyferra',
    'Toprawa',
    'Vortex',
    'Wayland',
    'Zonama Sekot'
]
const randomNameGenerator = () => {
    let randomNumber = Math.floor(Math.random() * starWarsMoonsAndPlanets.length);
    return starWarsMoonsAndPlanets[randomNumber];
}

export {randomNameGenerator};