import { Component } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default class GenericDialogActionTitle extends Component {
    constructor (props) {
        super(props);   
    }
    render () {
        return (
            <DialogTitle>
                { this.props.title }
                <IconButton
                    aria-label="close"
                    onClick={this.props.action}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        );
    }
}
