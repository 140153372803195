import { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';

class DeleteSet extends Component {
    constructor (props) {
        super(props);
        this.deleteSetButton = this.deleteSetButton.bind(this);
    }
    deleteSetButton (e, setIndex) {
        try {
            this.props.deleteSet(this.props.sets[setIndex]);
        } catch (e) {
            // better error handling.
            console.error(e.message);
        }
        
        e.preventDefault();
    }
    renderSets () {
        if (this.props.sets && Array.isArray(this.props.sets) && this.props.sets.length > 1) {
            return this.props.sets.map((set, index) => {

                if (set !== 'default') {
                    return (
                        <ListItem
                            key={index}
                            sx={{pl: 0}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={(e) => { this.deleteSetButton(e, index)}}>
                                <DeleteIcon />
                            </IconButton>
                        }>
                            {set}
                        </ListItem>
                    );
                }
            })
        } else {
            return (
                <ListItem sx={{pl: 0}}>
                    No groups found.
                </ListItem>
            )
        }
    }
    render () {     
        return (
            <Dialog fullWidth={true} open={this.props.isDeleteSetDialogOpen} onClose={this.props.toggleDeleteSetDialog}>
                <GenericDialogActionTitle title="Delete Shared Group" action={this.props.toggleDeleteSetDialog} />
                <DialogContent>
                    Select from the following sets to delete:
                    <List>
                        { this.renderSets() }
                    </List>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDeleteSetDialog: () => dispatch({type: 'ui/toggleDeleteSetDialog'}),
        deleteSet: (payload) => {dispatch({type: "hyperlink/deleteSet", payload})}
    }
};

const mapStateToProps = (state) => {
    return {
        isDeleteSetDialogOpen: state.ui.isDeleteSetDialogOpen,
        sets: state.hyperlink.sets
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSet);