import { Component } from 'react';
import { connect } from 'react-redux';

import './Search.styles.css';

import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

const options = ['Option 1', 'Option 2'];

class Search extends Component {

    constructor (props) {
        super(props);

        this.clearSearchTerm = this.clearSearchTerm.bind(this);
    }

    clearSearchTerm (evt) {
        this.props.setSearchTerm('');
    }

    renderAdornment () {
        if (this.props.searchTerm !== '') {
            return(
                <IconButton
                    aria-label="Clear Search Term"
                    onClick={this.clearSearchTerm}
                    onMouseDown={this.clearSearchTerm}
                    edge="end"
                    sx={{color: '#73869b'}}
                    >
                    <CancelIcon/>
                </IconButton>
            )
        } else {
            return (
                <SearchIcon sx={{color:'#485b6e'}} />
            );
        }
    }

    render () {
        return (
            <TextField
                value={this.props.searchTerm}
                className='link-search'
                placeholder="Quick Search"
                margin='dense'
                sx={{ 
                    mt: 0, 
                    mb: 0, 
                    width: '45ch',
                }}
                onChange={ (event) => {
                    this.props.setSearchTerm(event.target.value);
                }}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        { this.renderAdornment() }
                    </InputAdornment>,
                }}
            />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchTerm: (payload) => {dispatch({type: "ui/setSearchTerm", payload})},
    }
};

const mapStateToProps = state => {
    return {
      searchTerm: state.ui.searchTerm
    }
 }

 export default connect(mapStateToProps, mapDispatchToProps)(Search);