import aesjs  from 'aes-js';

const encryptGroups = (key, groups) => {
    if (key && groups) {
      let keyInBytes = aesjs.utils.utf8.toBytes(key);
      let currentGroups = JSON.stringify(groups);
      let groupInBytes = aesjs.utils.utf8.toBytes(currentGroups);
      var aesCtr = new aesjs.ModeOfOperation.ctr(keyInBytes, new aesjs.Counter(5));
      var encryptedGroupInBytes = aesCtr.encrypt(groupInBytes);
      var encryptedGroupInHex = aesjs.utils.hex.fromBytes(encryptedGroupInBytes);
      return encryptedGroupInHex;
    }
  }
  
const decryptGroups = (key, encryptedGroups) => {
    if (key && encryptedGroups) {
      let keyInBytes = aesjs.utils.utf8.toBytes(key);
      var encryptedGroupInBytes = aesjs.utils.hex.toBytes(encryptedGroups);
      var aesCtrDecrypt = new aesjs.ModeOfOperation.ctr(keyInBytes, new aesjs.Counter(5));
      var decryptedGroupInBytes = aesCtrDecrypt.decrypt(encryptedGroupInBytes);
      var groups = aesjs.utils.utf8.fromBytes(decryptedGroupInBytes);
      return groups;
    }
}

export {encryptGroups, decryptGroups};