
const toggleEditMode = (state) => {
    return {
        ...state,
        isEditModeDisabled: !state.isEditModeDisabled
    }
}

const toggleNewGroupDialog = (state) => {
    return {
        ...state,
        isNewGroupDialogOpen: !state.isNewGroupDialogOpen,
        isSuperFabOpen: false
    }
}

const toggleExportDialog = (state) => {
    return {
        ...state,
        isExportDialogOpen: !state.isExportDialogOpen,
        isSuperFabOpen: false
    }
}

const toggleImportDialog = (state) => {
    return {
        ...state,
        isImportDialogOpen: !state.isImportDialogOpen,
        isSuperFabOpen: false
    }
}

const toggleSetFilter = (state, action) => {
    state.currentSet = (action.payload) ? action.payload : 0;
}

const toggleAccountDrawer = (state) => {
    return {
        ...state,
        isAccountDrawerOpen: !state.isAccountDrawerOpen,
        isSuperFabOpen: false
    }
}

const toggleDeleteSetDialog = (state) => {
    return {
        ...state,
        isDeleteSetDialogOpen: !state.isDeleteSetDialogOpen,
        isSuperFabOpen: false
    }
}
const toggleReleaseNotesDialog = (state) => {
    return {
        ...state,
        isReleaseNotesDialogOpenV1: !state.isReleaseNotesDialogOpenV1,
        isSuperFabOpen: false
    }
}

const setSearchTerm = (state, action) => {
    state.searchTerm = (action.payload) ? action.payload : '';
}

const openSuperFab = (state, action) => {
    return {
        ...state,
        isSuperFabOpen: true
    }
}

const closeSuperFab = (state) => {
    return {
        ...state,
        isSuperFabOpen: false
    }
}

export { 
    toggleEditMode, 
    toggleNewGroupDialog, 
    toggleExportDialog, 
    toggleImportDialog, 
    toggleSetFilter, 
    toggleAccountDrawer, 
    toggleDeleteSetDialog,
    toggleReleaseNotesDialog,
    setSearchTerm, 
    openSuperFab, 
    closeSuperFab
};