import { Component } from 'react';
import Alert from '@mui/material/Alert';

import './NotificationError.styles.css';

export default class NotificationError extends Component {
    constructor (props) {
        super(props);   
    }

    renderError () {
        if (this.props.message) {
            return (
                <Alert severity="error" xs={12}>{this.props.message}</Alert>
            )
        } else {
            return (<span></span>)
        }
    }
    render () {
        return this.renderError();
    }
}
