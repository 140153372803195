import { createRef, Component } from 'react';
import { connect } from 'react-redux';

import { decryptGroups } from '../../utils/helpers/crypto.helpers';

import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import NotificationError from '../NotificationError/NotificationError';
import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';
import { randomNameGenerator } from '../../utils/helpers/randomNameGenerator';

class ExportSet extends Component {
    constructor (props) {
        super(props);
 
        this.state = {
            errorMsg: '',
            errorImport: ''
        };

        this.encryptedSet = createRef('');
        this.encryptionKey = createRef('');
        this.userGeneratedSetName = createRef('');
        this.handleClickImport = this.handleClickImport.bind(this);
    }

    handleClickImport (e) {
        if (this.encryptionKey) {
            let key = this.encryptionKey.current.value;
            if (key.length === 16 || key.length === 24 || key.length === 32) {
                if (this.encryptedSet && this.encryptedSet.current && this.encryptedSet && this.encryptedSet.current.value) {
                    let encryptedSet = this.encryptedSet.current.value;
                    try {
                        let decryptedSet = decryptGroups(key, encryptedSet);
                        if (decryptedSet) {
                            let set = JSON.parse(decryptedSet);

                            if (set && Array.isArray(set)) {
                                let userGeneratedSetName = this.userGeneratedSetName.current.value
                                let setName = (userGeneratedSetName) ? userGeneratedSetName : randomNameGenerator();
                                let groups = set.map( (group) => {
                                    group.set = `${setName}`;
                                    return group;
                                });
                                this.props.insertSet(`${setName}`);
                                this.props.addGroup(groups);
                                this.props.toggleImportDialog();
                            } else {
                                throw new Error('Not Array');
                            }
                        } else {
                            throw new Error('Error Decrypting');
                        } 
                        
                    } catch (error) {
                        this.setState({
                            errorMsg: `Unable to decrypt Set. This could be due to an incorrect secret or corrupted export. ${error.message}`
                        })
                    }
                } else {
                    this.setState({
                        errorMsg: 'Set empty.'
                    })
                } 
            } else {
                this.setState({
                    errorMsg: 'Secret must be 16 (AES128), 24 (AES192), or 32 (AES256) characters in length.'
                });
            }
            
        }
    }

    render () {     
        return (
            <Dialog fullWidth={true} open={this.props.isImportDialogOpen} onClose={this.props.toggleImportDialog}>
                <GenericDialogActionTitle title="Import Links" action={this.props.toggleImportDialog} />
                <NotificationError message={this.state.errorMsg} />
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="encryption-key"
                        label="Secret"
                        fullWidth
                        variant="outlined"
                        helperText="The known secret used to decrypt links collection."
                        inputRef={this.encryptionKey}
                        aria-describedby="component-error-text"
                    />
                    <TextField
                        margin="dense"
                        id="user-generated-set-name"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        helperText="Name used for the creation of a new tab."
                        inputRef={this.userGeneratedSetName}
                        aria-describedby="component-error-text"
                    />
                    <TextareaAutosize
                        className="five-i-textarea"
                        aria-label="Paste your encrypted Set here."
                        minRows={15}
                        placeholder="Paste your encrypted Set here."
                        ref={this.encryptedSet}
                        style={{ width: '94%', marginTop: '20px' }}
                        aria-describedby="component-error-text"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClickImport}>Import</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        insertSet: (payload) => dispatch({type: 'hyperlink/insertSet', payload}),
        addGroup: (payload) => dispatch({type: 'hyperlink/addGroup', payload}),
        toggleImportDialog: () => dispatch({type: 'ui/toggleImportDialog'}),
        importSet: (payload) => dispatch({type: 'ui/toggleImportDialog', payload}),
    }
};

const mapStateToProps = (state) => {
    return {
        isImportDialogOpen: state.ui.isImportDialogOpen,
        sets: state.hyperlink.sets,
        groups: state.hyperlink.groups
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportSet);