
import { Component } from 'react';

import Header from '../../components/Header/Header';
import Container from '@mui/material/Container';
import Groups from '../../components/Groups/Groups';
import SuperFab from '../../components/SuperFab/SuperFab';

export default class HomePage extends Component {

  render() { 
    return (
      <Container maxWidth={false} disableGutters={true}>
        <Header />
        <Container maxWidth="xl" className="starty">
          <Groups />
        </Container>
        <SuperFab />
      </Container>
    );
  }
}