import { createSlice } from '@reduxjs/toolkit'
import { insertSet, addGroup, addNewLinkToGroup, deleteGroupOrUrl, editMode, importSet, deleteSet} from './hyperlinks.actions';

const initialState = {
    errMsg: undefined,
    currentSet: 'default',
    sets: [
        'default'
    ],
    groups: [
      {
        name: 'PERSONAL',
        set: 'default',
        collection: [
          {
            name: `Visit 5i.io founder's blog`,
            url: 'https://www.codeless.me',
            alias: 'codeless',
            tags: [
              'codeless',
              'social',
              '5i.io'
            ]
          },
        ]
      },
    ]
}

export const hyperlinkSlice = createSlice({
  name: 'hyperlink',
  initialState,
  reducers: {
    insertSet,
    addGroup,
    addNewLinkToGroup,
    deleteGroupOrUrl,
    editMode,
    importSet,
    deleteSet,
    clearError: (state) => {
      return state.errMsg = undefined;
    }
  },
})

export default hyperlinkSlice.reducer