
import md5 from 'md5';

export default class UrlModel {
    constructor(name, url, alias, tags) {
        this.name = name;
        this.url = url;
        this.alias = alias;
        this.tags = tags;
        
        this.url = this.ensureProtocol(url);
        this.alias = (alias) ? alias.toLowerCase() : this.generateAlias(url, name);
        this.createdDate = Date.now();
        this.isValid();
        console.log(this);
        return this;
    }
    isValid () {
        let isValid = true;
        if (this.url || this.url === '') { return false; }
        return isValid;
    }
    ensureProtocol (url) {
        let newUrl = (url && (url.substring(0,8) === 'https://' || url.substring(0,7) === 'http://' || url.substring(0,8) === 'file:///')) ? url : 'https://' + url;
        if (newUrl === 'https://') {
            return url;
        } else {
           
            return newUrl; 
        }
    }
    generateAlias (url) {
        // Use time to salt and create unique hashes
        let date =  new Date();
        // Create salted URL hash
        let hash = md5(url+date)
        // return the first 7 characters
        return hash.substring(0,7).toLowerCase();
    }
}