import { Component } from 'react';
import { connect } from 'react-redux';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import Search from '../Search/Search';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';
import './Header.styles.css';
import { ReactComponent as Logo } from './five-eye-logo.svg';

  

class Header extends Component {
    constructor (props) {
        super(props);
        this.state = {
            anchorEl: null,
            setAnchorEl: null,
            open: false,
            isAboutToggled: false,
            menu: [
                {
                    isActive: false,
                    title: 'Backups',
                    description: 'Home Dashboard'
                },
                {
                    isActive: true,
                    title: 'About',
                    action: this.toggleAbout,
                    description: 'Home Dashboard'
                }
            ]
        }
        this.toggleAbout = this.toggleAbout.bind(this);
    }
    renderCollections () {
        return this.props.sets.map((item, index) => {
            return  (
                <Button
                    key={index}
                    id={`${item}-menu-button`}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="text"
                    aria-haspopup="true"
                    onClick={(event) => { this.props.toggleSetFilter(index);}}
                >
                    {(item === 'default') ? 'Home' : item}
                </Button>
            );
        });
    }
    renderMenuButtons () {
        return this.state.menu.map((item, index) => {
            if (item.isActive) {
                return (
                    <Button
                        key={index}
                        id={`${item.title}-menu-button`}
                        disableRipple={true}
                        disableFocusRipple={true}
                        variant="text"
                        aria-haspopup="true"
                        onClick={(event) => { item.action(this, event)}}
                    >
                        {item.title}
                    </Button>
                );
            }
        })
    }

    toggleAbout (component) {
        if (!component.state) {
            component = this;  
        } 
        component.setState({
            isAboutToggled: !component.state.isAboutToggled
        })
    }

    renderUsedStorage () {
        let limit = 1024 * 1024 * 5; // 5 MB
        let remaining = limit - decodeURI(encodeURIComponent(JSON.stringify(localStorage))).length;
        let percentageUsed = 100-(remaining/limit*100);

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={percentageUsed} />
                </Box>
                <Box sx={{ minWidth: 80 }}>
                    <Typography variant="body2" color="text.default">
                        <strong>{percentageUsed.toFixed(2)}% Used</strong>
                    </Typography>
                </Box>
            </Box>
        )
    }

    render () {
        return (
            <div className="five-eye-header">
                <div className="five-eye-menu">
                    <Container maxWidth="xl">
                        <Grid container spacing={0} alignItems="center">
                            <Grid className='menu-search' item xs={12}>
                                <Search />
                                <IconButton sx={{mt:1, color:'#73869b'}} onClick={this.toggleAbout}>
                                    <InfoIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="branding-content">
                    <Container maxWidth="xl">
                        <Grid container spacing={0} justifyContent="center" alignItems="center">
                            <Grid item xs={1} className="brand-icon">
                                <Logo />
                            </Grid>
                            <Grid item xs={11} className="brand-content">
                                <h1>FIVE EYE</h1>
                                <h3>A decentralized bookmark and shortlink platform that values privacy!</h3>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Dialog
                    open={this.state.isAboutToggled}
                    onClose={this.toggleAbout}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <GenericDialogActionTitle title="About" action={this.toggleAbout} />
                    <DialogContent>
                        <DialogContentText id="ialog-description">
                        Furthermore, 5i.io provides a simple way to share your collection of links securely if you so choose. 
                        This means that each device has links, so others cannot access your links unless you share them. 
                        Lastly, our subscription backup service only keeps the encrypted value while you keep 
                        the secret, so don't forget it!
                        </DialogContentText>
                        <Box sx={{mt:2}}>
                            <strong>Available Storage</strong><br/>
                            { this.renderUsedStorage() }
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewLinkToGroup: (payload) => {dispatch({type: "hyperlink/addNewLinkToGroup", payload})},
        toggleSetFilter: (payload) => {dispatch({type: "ui/toggleSetFilter", payload})},
    }
};

const mapStateToProps = (state) => {
    return {
        sets: state.hyperlink.sets,
        currentSet: state.ui.currentSet,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);