const insertSet = (state, action) => { 
  if (action.payload) {
    state.sets.push(action.payload);
  }
}

const deleteSet = (state, action) => { 
  console.log(action)
  if (action.payload) {
    let currentGroups = JSON.parse(JSON.stringify(state.groups));
    let set = action.payload;
    let setGroups = currentGroups.filter( group => group.set === set )

    // Delete child groups
    if (setGroups && Array.isArray(setGroups) && setGroups.length > 0) {
      // iteration also happens in the 'deleteGroupOrUrl' method. This could be more efficient.
      setGroups.map( (group) => {
        let deleteAction = {
          payload: {
            type: 'deleteGroup',
            set,
            groupName: group.name
          }
        };
        console.log('Deleting Group', deleteAction)
        deleteGroupOrUrl(state, deleteAction);
        return group;
      });
    }
    
    // Delete set
    let sets = JSON.parse(JSON.stringify(state.sets));
    console.log(sets)
    let indexFound = sets.findIndex( setName => setName === set);
    if (indexFound >= 0) {
      console.log('Delete Set', set);
      state.sets.splice(indexFound, 1);
    }
  }
}

const addGroup = (state, action) => {
  // Fetch current groups
  let currentGroups = JSON.parse(JSON.stringify(state.groups));
  let newGroups = [];

  if (action.payload && action.payload.name) {
    // Group name to find for duplicates
    let name = action.payload.name.toUpperCase();
    // Set to look for
    let set = (action.payload.set) ? action.payload.set : 'default';
    let group = {
      name,
      set,
      collection: []
    }
    newGroups.push(group);
  }
  if (action.payload && Array.isArray(action.payload)) {
    newGroups = action.payload;
  }
  newGroups.map( (newGroup) => {
    // Look for duplicate Groups within a Set
    let indexFound = currentGroups.findIndex(group => group.name === newGroup.name && group.set === newGroup.set);
    // If the group isn't a duplicate in the set, create it.
    if (indexFound < 0) {
        console.log('Insert Group', newGroup);
        state.groups.push(newGroup);
    } else {
        state.errMsg = `Group ${newGroup.name} in ${newGroup.set} Set already exists or is empty`;
    }
    return newGroup;
  });
}

const addNewLinkToGroup = (state, action) => {
  if (action && action.payload && action.payload.group) {
    let urlModel = action.payload.urlModel;
    let set = (action.payload.set) ? action.payload.set : 'default';
    if ( urlModel && urlModel.name && urlModel.url) {
      let found = false;
      state.groups.map((group) => {
        if (group.name === action.payload.group && group.set === set) {
          found = true;
          return {
            ...state,
            groups: [
              ...state.groups,
              {...group, collection: group.collection.push(action.payload.urlModel)}
            ]
          }
        }
        return group;
      });
      if (!found) {
        throw new Error(`Group ${action.payload.group} not found in ${set} set. Unable to add link.`);
      }
    }
    else {
      throw new Error(`Incorrect payload provided when attempting to add new url. Missing name or url path.`);
    }
  } 
  else {
    throw new Error(`Incorrect payload provided when attempting to add new url to ${action.payload.group}.`);
  }
}

const deleteGroupOrUrl = (state, action) => {
  let currentGroups = JSON.parse(JSON.stringify(state.groups));
  let name = action.payload.groupName;
  let set = (action.payload.set) ? action.payload.set : 'default';
  
  let indexFound = currentGroups.findIndex( group => group.name === name && group.set === set);
  console.log(`Deleting group ${name} from ${set} set found at group index ${indexFound}`);
  if (action.payload.type === 'deleteGroup' && indexFound >= 0) {
    console.log(indexFound);
    state.groups.splice(indexFound, 1);
  } else if( action.payload.type === 'deleteURL' && indexFound >= 0) {
    state.groups[indexFound].collection.splice(action.payload.urlIndex, 1)
  } else {
    // TODO - nothing to delete
  }
}

const importSet = (state, action) => {
  if (action.payload) {
    let groups = action.payload;

    console.log(groups);
  }
}

const editMode = (state) => {
    state.editMode = !state.editMode;
}

export {insertSet, addGroup, addNewLinkToGroup, deleteGroupOrUrl, editMode, importSet, deleteSet };