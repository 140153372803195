import { Component } from 'react';
import { connect } from 'react-redux';

import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';

class AccountDrawerButton extends Component {

    renderButtonType() {
        if (this.props.isDrawerView) {
            return (
                <IconButton aria-label="Close" onClick={this.props.toggleAccountDrawer}>
                    <CloseIcon />
                </IconButton>
            );
        } else {
            return (
                <Fab className="modification-icon" size="small" aria-label="Edit" color={ (!this.props.isAccountDrawerOpen) ? 'default' : 'primary'} onClick={this.props.toggleAccountDrawer}>
                    <BackupIcon />
                </Fab>
            )
        }
    }
    render () {
        return (
            <span>
                { this.renderButtonType() }
            </span>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        toggleAccountDrawer: () => {dispatch({type: "ui/toggleAccountDrawer"})},
    }
};

const mapStateToProps = (state) => {
    return {
        isAccountDrawerOpen: state.ui.isAccountDrawerOpen,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDrawerButton);