
import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import ListItemButton from '@mui/material/ListItemButton';
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';
import NotificationError from '../NotificationError/NotificationError';
import UrlModel from './UrlModel';

import './AddUrl.styles.css';

class AddUrl extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            errorMsg: ''
        };
        this.name = createRef('');
        this.url = createRef('');
        this.alias = createRef('');
        this.tags = createRef('');
        this.handleClickClose = this.handleClickClose.bind(this);
        this.addNewLinkButton = this.addNewLinkButton.bind(this);
        this.openAddDialog = this.openAddDialog.bind(this);
    }
    handleClickClose () {
        this.setState({
            open: false,
            errorMsg: ''
        })
    }
    openAddDialog (e) {
        e.preventDefault();
        this.setState({
            open: true
        })
    }
    addNewLinkButton (e) {
        e.preventDefault();
        let shortUrl = new UrlModel(this.name.current.value, this.url.current.value, this.alias.current.value, this.tags.current.value)
        try {
            let set = this.props.sets[this.props.currentSet];
            this.props.addNewLinkToGroup({
                group: this.props.group,
                urlModel: shortUrl,
                set
            });
            this.setState({
                errorMsg: '',
                open: false
            })
        } catch (error) {
            this.setState({
                errorMsg: `Unable to add group due to the following: ${error.message}`
            })
        }
        
    }
    render () {
        return (
            <div className='add-button'>
                <ListItemButton component="a" className="list-item-new" onClick={this.openAddDialog}>
                    <AddCircleOutlineOutlined />
                </ListItemButton>
                <Dialog fullWidth={true} open={this.state.open} onClose={this.handleClickClose}>
                    <GenericDialogActionTitle title={ `Add new link to ${this.props.group} group.`} action={this.handleClickClose} />
                    <NotificationError message={this.state.errorMsg} />
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            fullWidth
                            variant="outlined"
                            helperText="Describe your link."
                            inputRef={this.name}
                            aria-describedby="component-error-text"
                        />
                        <TextField
                            margin="dense"
                            id="url"
                            label="Redirect URL"
                            fullWidth
                            variant="outlined"
                            helperText="Your link desitination."
                            inputRef={this.url}
                            aria-describedby="component-error-text"
                        />
                        <TextField
                            margin="dense"
                            id="shortLink"
                            label="Alias (Auto-generated if Blank)"
                            fullWidth
                            variant="outlined"
                            helperText="Creates a routable alias such as https://5i.io/myalias."
                            inputRef={this.alias}
                            aria-describedby="component-error-text"
                        />
                        <TextField
                            margin="dense"
                            id="shortLink"
                            label="Tags (Comma Delimited)"
                            fullWidth
                            variant="outlined"
                            helperText="Everyone always needs tags!"
                            inputRef={this.tags}
                            aria-describedby="component-error-text"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickClose}>Cancel</Button>
                        <Button onClick={this.addNewLinkButton}>Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addNewLinkToGroup: (payload) => {dispatch({type: "hyperlink/addNewLinkToGroup", payload})},
    }
};

const mapStateToProps = (state) => {
    return {
        errMsg: state.hyperlink.errMsg,
        sets: state.hyperlink.sets,
        currentSet: state.ui.currentSet
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUrl);