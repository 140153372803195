import { createRef, Component } from 'react';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';

class NewCollection extends Component {
    constructor (props) {
        super(props);   
        this.newGroupName = createRef('');

        this.handleClickCreateGroup = this.handleClickCreateGroup.bind(this);
    }

    handleClickCreateGroup (e) {
        let set = (this.setValue && this.setValue.current) ? this.setValue.current : 'default';
        if (this.newGroupName && this.newGroupName.current) {
            let name = this.newGroupName.current.value;
            let newGroup = {
                name, 
                set
            }
            this.props.addGroup(newGroup);
        } 
        if (this.props.errMsg === undefined) {
            this.props.toggleNewGroupDialog();
        }
    }

    render () {     
        return (
            <Dialog fullWidth={true} open={this.props.isNewGroupDialogOpen} onClose={this.handleClickNewGroup}>
                <GenericDialogActionTitle title="Create New Link Collection" action={this.props.toggleNewGroupDialog} />
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Collection Name"
                        fullWidth
                        variant="outlined"
                        helperText="The name used to organize links."
                        inputRef={this.newGroupName}
                        aria-describedby="component-error-text"
                    />
                    {(this.props.errMsg) ? <FormHelperText id="component-error-text">{this.props.errMsg}</FormHelperText> : ''}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClickCreateGroup}>Create</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGroup: (payload) => dispatch({type: 'hyperlink/addGroup', payload}),
        toggleNewGroupDialog: () => dispatch({type: 'ui/toggleNewGroupDialog'}),
        clearError: () => dispatch({type: 'hyperlink/clearError'})
    }
};

const mapStateToProps = (state) => {
    return {
        errMsg: state.hyperlink.errMsg,
        isNewGroupDialogOpen: state.ui.isNewGroupDialogOpen
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCollection);