
import { Component } from 'react';
import { connect } from 'react-redux';
import './Collection.styles.css';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Tooltip from '@mui/material/Tooltip';


import CopyButton from '../CopyButton/CopyButton';
import AddUrl from '../AddUrl/AddUrl';

const deleteTypes = {
    group: 'deleteGroup',
    url: 'deleteURL'
}

class Collection extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false
        };

        this.collection = this.props.collection;
        this.clickDelete = this.clickDelete.bind(this);
    }
    
    buildListItems () {
        if (this.props.collection && this.props.collection.length > 0) {
            return this.props.collection.map((item, index) => (
                <ListItemButton component="a" className="list-item-highlight" key={index} href={item.url} target="_blank" rel="noreferrer">
                    { this.toggleEditIcon(deleteTypes.url, index)}
                    <ListItemText secondary={item.url} >
                        <strong>{item.name}</strong>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        /{item.alias}
                    </ListItemSecondaryAction>
                    <CopyButton content={`${window.location}${(this.props.currentSet === 0) ? '' : this.props.currentSet + '/'}${item.alias}`} />
                </ListItemButton>
            ));
        }
    }
    clickDelete (e) {
        if (e.target && e.target.parentElement && e.target.parentElement.className) {
            console.log(e.target);
            let classNames = e.target.parentElement.className;
            let foundGroup = classNames.indexOf(deleteTypes.group);
            let foundUrl = classNames.indexOf(deleteTypes.url);
            let groupSetIndexFound = classNames.indexOf(`groupSetIndex-`);
            let groupSetIndex = classNames.substring(groupSetIndexFound+14,groupSetIndexFound+15);
            let set = this.props.sets[groupSetIndex];
            let action = {};

            if (foundGroup >= 0) {
                action = {
                    type: deleteTypes.group,
                    groupName: this.props.groupName,
                    set
                }
            } 
            else if (foundUrl >= 0) {
                let found = classNames.indexOf(`url-`);
                let urlIndex = classNames.substring(found+4,found+5);
                action = {
                    type: deleteTypes.url,
                    groupName: this.props.groupName,
                    set,
                    urlIndex
                }
                
            }
            console.log('Delete Action:', action)
            this.props.deleteGroupOrUrl(action);
        }
        e.preventDefault();
        e.stopPropagation();
    }
    toggleEditIcon (type, index) {
        if (!this.props.editMode) {
            let setIndex = -1;
            if (this.props.sets) {
                setIndex = this.props.sets.indexOf(this.props.groupSet);
            }
            return (
                <IconButton className={`${type} groupSetIndex-${setIndex} ${ (index !== undefined) ? 'url-' + index : ''}`} aria-label="delete" size="small" color="error" onClick={this.clickDelete}>
                    <RemoveIcon fontSize="inherit" />
                </IconButton>
            );
        }
    }

    renderImportedIcon () {
        if (this.props.groupSet && this.props.groupSet !== 'default') {
            return (
                <Tooltip title={`Imported Collection. Group: ${this.props.groupSet}`} placement="top" arrow>
                    <IconButton aria-label="Imported" size="small" color="default">
                        <FolderSpecialIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            );
        }
    }

    render () {
        return (
            <Grid className='group-container' item xs={12} md={6}>
                <Typography className={ (this.props.editMode) ? 'group-header' : 'group-header group-edit'} variant="h6" component="div"> 
                    { this.toggleEditIcon(deleteTypes.group) }
                    { this.renderImportedIcon() }
                    { this.props.groupName }
                </Typography>
                <List aria-label="collection folders">
                    {this.buildListItems()}
                    <AddUrl group={this.props.groupName} />
                </List>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewLinkToGroup: (payload) => {dispatch({type: "hyperlink/addNewLinkToGroup", payload})},
        deleteGroupOrUrl: (payload) => {dispatch({type: "hyperlink/deleteGroupOrUrl", payload})}
    }
};

const mapStateToProps = (state) => {
    return {
        sets: state.hyperlink.sets,
        editMode: state.ui.isEditModeDisabled,
        currentSet: state.ui.currentSet
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
