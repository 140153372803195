import { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import searchV1Image from './search-v1.png';
import unifiedLinksGroupsCollections from './unified-link-groups-collections.png';
import fabLife from './fab-life.png';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';

const notes = [
    {
        name: 'April 2023',
        description: 'blah',
        releaseItems: [
            {
                title: 'Global Search',
                description: 'Sweet and to the point, search is now a first-class experience on Five Eye. Sorry, it took us this long to incorporate it, but we hope you love it!',
                image: searchV1Image
            },
            {
                title: 'Floating Action Button (FAB)',
                description: `Our original menu system was great when our users only managed a few 
                dozen links, but it quickly revealed its flaws at scale. So we are now introducing 
                a global Floating Action Button (FAB) that persists at the bottom right corner of your 
                screen. In addition, the FAB is more intuitive than before because each action now has a tooltip. We 
                know we were crazy not to include tooltips in the beginning, but forgive us because we are only human. We 
                hope you enjoy the new menu system, and if you are feeling up to it, buy us a drink (located as a FAB action now).`,
                image: fabLife
            },
            {
                title: 'Unified Link Groups & Collections',
                description: `We love how our users collaborate and share links, but we often hear feedback 
                regarding how we manage imported link Groups. Group interactions involved naming imported groups and 
                switching between link Groups in the menu bar. We are happy to announce that your link Collections 
                live harmoniously with the imported link Groups & Collections. You will now see a new folder designation 
                next Collections informing you that they are part of an imported shared Group. Hovering over the 
                icon will let you know what link Group they are part of, making it easier to delete 
                the Groups when desired.`,
                image: unifiedLinksGroupsCollections
            }
        ]
    }
]

class ReleaseNotes extends Component {
    constructor (props) {
        super(props);
    }

    renderMedia (image, alt) {
        if (image) {
            return (
                <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt={alt}
                />
            )
        }
    }
    renderReleaseItems (releaseItem) {
        return releaseItem.map( (item, index) => {
            return (
                <ListItem sx={{pl:0}} key={index}>
                    <Card sx={{ width: '100%'}}>
                        <CardActionArea>
                            { this.renderMedia(item.image, item.title)}
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </ListItem>
            )
        })
    }
    renderNotes() {
        return notes.map((note, index) => {
            return (
                <Fragment key={index}>
                    <Typography sx={{mt:3}} variant="h4" component="div">
                        { note.name }
                    </Typography>
                    <List>
                        { this.renderReleaseItems(note.releaseItems) }
                    </List>
                </Fragment>
            )
        })
    }

    render () {     
        return (
            <Dialog fullWidth={true} open={this.props.isReleaseNotesDialogOpenV1} onClose={this.props.toggleReleaseNotesDialog} >
                <GenericDialogActionTitle title="Release Notes" action={this.props.toggleReleaseNotesDialog} />
                <DialogContent sx={{ backgroundColor:'#161616'}}>
                    { this.renderNotes() }
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleReleaseNotesDialog: () => dispatch({type: 'ui/toggleReleaseNotesDialog'}),
    }
};

const mapStateToProps = (state) => {
    return {
        isReleaseNotesDialogOpenV1: state.ui.isReleaseNotesDialogOpenV1,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);