import { createSlice } from '@reduxjs/toolkit'

import { 
  toggleEditMode, 
  toggleNewGroupDialog, 
  toggleExportDialog, 
  toggleImportDialog, 
  toggleSetFilter, 
  toggleAccountDrawer, 
  toggleDeleteSetDialog,
  toggleReleaseNotesDialog,
  setSearchTerm, 
  openSuperFab, 
  closeSuperFab} from './ui.actions'

const initialState = {
    isEditModeDisabled: true,
    isNewGroupDialogOpen: false,
    isExportDialogOpen: false,
    isImportDialogOpen: false,
    isAccountDrawerOpen: false,
    isDeleteSetDialogOpen: false,
    isReleaseNotesDialogOpenV1: true,
    isSuperFabOpen: false,
    currentSet: 0,
    searchTerm: ''
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleEditMode,
    toggleNewGroupDialog,
    toggleExportDialog,
    toggleImportDialog,
    toggleSetFilter,
    toggleAccountDrawer,
    toggleDeleteSetDialog,
    toggleReleaseNotesDialog,
    setSearchTerm,
    openSuperFab,
    closeSuperFab
  },
})

export default uiSlice.reducer