import { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import GenericDialogActionTitle from '../GenericDialogActionTitle/GenericDialogHeader';
import CreateSnapshot from '../CreateSnapshot/CreateSnapshot';

class ExportSet extends Component {
    constructor (props) {
        super(props);
    }

    render () {     
        return (
            <Dialog fullWidth={true} open={this.props.isExportDialogOpen} onClose={this.props.toggleExportDialog}>
                <GenericDialogActionTitle title="Create a Shareable Group" action={this.props.toggleExportDialog} />
                <DialogContent>
                    <CreateSnapshot />
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleExportDialog: () => dispatch({type: 'ui/toggleExportDialog'}),
    }
};

const mapStateToProps = (state) => {
    return {
        isExportDialogOpen: state.ui.isExportDialogOpen,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportSet);