import { Component } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

import './CopyButton.styles.css';

export default class CopyButton extends Component {
    constructor (props) {
        super(props);   

        this.clickCopyButton = this.clickCopyButton.bind(this);
    }

    clickCopyButton (e) {
        navigator.clipboard.writeText(this.props.content);
        e.preventDefault();
    }
    render () {
        return (
            <IconButton className="content-copy" edge="end" aria-label="copy" disabled={!this.props.content} onClick={this.clickCopyButton}>
                <ContentCopyIcon />
                <span className="copy-label" style={{display: (this.props.copyLabel) ? 'inline-flex' : 'none'}}>
                    {this.props.copyLabel}
                </span>
            </IconButton>
        );
    }
}
